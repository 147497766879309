/* @import url('https://fonts.cdnfonts.com/css/clicker-script'); */

.our-centers .Image{
    margin-bottom: 60px;
    padding-top: 60px;
    padding-bottom: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 40vh;
}
.our-centers .bannerImage{
    background-image: url('/public/images/header-centers.jpg');  
}
.our-centers .bannerImage1{
    background-image: url('/public/images/header-about.jpg');
    
}
.our-centers .bannerImage2{
    background-image: url('/public/images/header-therapist.jpg');
    
}
.our-centers .bannerImage3{
    background-image: url('/public/images/header-diagnoses.jpg');
    
}
.our-centers .bannerImage4{
    background-image: url('/public/images/header-specialist.jpg');
    
}
.our-centers .our_centers_text{
    color: #ffffff;
    font-size: 32px;
    font-weight: 700;
    border-left: 5px solid #f5a81c;
    padding-left: 15px;
    margin-bottom: 15px;
    max-width: 100%;
    margin-left: 5%;
}
.our-centers .our_centers_heading{
    font-size: 32px;
    font-weight: 700;
    color: #2e3b97;  
    margin-bottom: 1px;
    max-width: 100%;
    
}
.our-centers .our_centers_small_text{
    font-size: 17px;
    color: #2e3b97;  
    margin-bottom: 15px;
    max-width: 100%;
}
.our-centers .para{
    color: #616161;
}
.our-centers .vcex-heading{
    font-family: 'Clicker Script', sans-serif;
    color: #2e3b97;
    text-transform: capitalize;
    padding-top: 20px;
}
.our-centers .PdfPage{
    display: flex;
    justify-content: center;
    align-items: center;
}
.react-pdf__Document {
    display: inline-block;
    width: 100%;
    height: 232px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .react-pdf__Page {
    /* width: 1000px; */
    height: 224px;
  }
  .stf__parent canvas{
    width: 1000px;
    height: 224px;
  }
.our-centers .vc_column_container {
    -webkit-box-align: center;
    align-items: center;
}
.our-centers .vc_column-inner {
    justify-content: center;
}
.our-centers .vc_column-inner::before {
    content: " ";
    display: table;
}
.wpb_content_element .wpb-js-composer .vc_tta-container {
    margin-bottom: var(--wpex-vc-elements-bottom-margin, 0px);
}
.our-centers .vc_custom{
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #2e3b97;
    margin: 40px 0px;
}
.our-centers .vc_tta-tab{
    margin: 15px 0px;
    border-radius: 5px;
    padding: 14px;
    border-color: #e3e3e3;
    background-color: #ebebeb;
    color: #666;
    font-size: 0.9em;
    
}
.our-centers .vc_tta_actv{
    border: 1px solid #2e3b97;
    background-color: #2e3b97;
    color: #fff;
    margin: 15px 0px;
    border-radius: 5px;
    padding: 14px;
    font-size: 0.9em;
}
.our-centers .vc_tta-Image{
    width: 100%;
    height: auto;
    vertical-align: top;
    margin: 15px 0px;
}
.our-centers .vc_label{
    color: #2e3b97;
    font-size: 22px;
    font-weight: 700;
    text-transform: capitalize;
    /* padding-top: 30px;*/
} 
.our-centers .vcex-place{
    color: #2e3b97;
    font-size: 16px;
    text-transform: capitalize;
    padding-bottom: 30px;
    font-weight: 400;
    /* margin: auto; */
}
.our-centers .wpb_wrapper{
    color: #2e3b97;
    font-size: 16px;
    padding-bottom: 30px;
}
.our-centers .vcx_button{
    font-size: .923em;
    background-color: #2e3b97;
    color: #fff;
    border-radius: 5px;
    padding: 9px;
    text-decoration: none;
}
.map_wrapper {
    max-width: 100%;
    width: 100%;
    height: 100%;
}
.map_wrapper .map_aspect_ratio {
    max-width: 100%;
    width: 100%;
    position: relative;
    height: 0;
}
.map_wrapper .map_container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.vc_custom_1663055695134 {
    padding-top: 100px !important;
    padding-bottom: 80px !important;
    background-color: #2e3b97 !important;
}
.vc_custom_1593502915043{
    box-shadow: 0px 4px 10px 0px #dedede;
    border: 1px solid #e5e5e5;
    width: 23%;
    margin-right: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px 100px 50px 100px;

}
.vc_custom_diagnosis{
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #f8f8f8;
}
.diagnoseImage{
    object-fit: contain;
    margin-right: 10px;
    height: 50%;
}
.AdvisoryImage{
    object-fit: contain;
    width: 100%;
}