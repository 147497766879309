

  /* Style the video: 100% width and height to cover the entire window */
.healthcare #myVideo {
  position: relative;
  width: 100%;
}

/* Add some content at the bottom of the video/page */
.healthcare .content {
  position: absolute;
  top: 10rem;
  color: #f1f1f1;
  padding: 20px;
}
.healthcare .more-btn{
    border: none;
    background-color: transparent;
    color: #ffffff;
    font-size: 18px;
}
.healthcare .request-btn{
  border: 2px solid #ffffff;
    background-color: transparent;
    color: #fff;
    padding: 2px;
    border-radius: 30px;
    font-size: 13px;
    
}

/* Style the button used to pause/play the video */
.healthcare #myBtn {
  font-size: 18px;
  border: none;
  background: transparent;
  color: #fff;
  cursor: pointer;
}

.healthcare #myBtn:hover {
  background: #ddd;
  color: black;
}
.healthcare .banner-header{
  color: #ffffff;
  font-size: 20px;
    line-height: 1.0526;
    font-weight: 300;
  
}
.possible .component-hero{
  background: #0057B8;
  padding: clamp(24px, 6.4vw, 64px) 10px;
}
.possible .head{
  font-size: clamp(36px, 4vw, 40px);
    font-family: 'mayo-display',serif;
    line-height: 1.1em;
    color: #000;
    margin-bottom: 0.3em;
    font-weight: 700;
}
.possible .para{
  font-size: clamp(20px, 2vw, 20px);
  line-height: 1.4em;
  margin-bottom: 0;
}
.possible .component-hero ul{
  padding: 0;
  list-style: none;
  position: relative;
  grid-column: 2 / span 10;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15vw;
}
.possible .head1{
  line-height: 34px;
  color: #fff;
    font-family: 'mayo-display';
    font-size: clamp(32px, 3.2vw, 32px);
    font-weight: 700;
    
}
.possible .para1{
  color: #fff;
    font-size: 20px;
    font-weight: 700;
    text-decoration: none;
    /* display: flex;
    align-items: flex-start; */
}
.possible .vertical-line{
  border-right: 1px solid #000;
  padding: 0 clamp(20px, 3.2vw, 32px);
}
.possible .vertical-line2{
  border-bottom: 1px solid #fff;
  margin: 28px 0px;
}
.possible .two-gap{
  margin: clamp(40px, 7vw, 72px) 0;
}
.possible .con-find-out{
  color: #fff;
  font-family: 'mayo-display',sans-serif;
    font-size: clamp(32px, 3.2vw, 32px);
    font-weight: 400;
    padding: clamp(24px, 3.2vw, 32px) clamp(24px, 4vw, 40px);
    text-decoration: none;
    line-height: 1em;
   
}
.possible .con-find-out em{
  font-style: normal;
    font-size: clamp(24px, 2.4vw, 24px);
    display: block;
    font-family: inherit;
    
}
.possible .long-arrow{
  padding-top: 70px;
  text-align: end;
}
.possible .con-text{
  min-height: 160px;
}
@media (min-width: 992px){
  .healthcare .banner-header {
      font-size: 3.375rem;
      
  }
  .possible .vertical-line2{
    border-left: 1px solid #fff;
    margin-left: 8%;
  }
  .possible .component-hero{
    padding: clamp(24px, 6.4vw, 64px) 61px;
  }
  .healthcare .content {
    top: 26rem;
  }
  .healthcare .request-btn{
    border: 2px solid #ffffff;
      background-color: transparent;
      color: #fff;
      padding: 11px;
      border-radius: 30px;
      font-size: 23px;
      margin-left: 30px;
  }
 
}