.maxWidthContainerFluid {
    max-width: 1600px;
    margin: 0 auto;
}
.main-titles{
    text-align: center;
    background-color: #000;
    color: #fff;
    height: 45px;
    padding-top: 5px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

@media (max-width: 1600px) {
    .maxWidthContainerFluid {
        max-width: 1400px;
        margin: 0 auto;
    }
}
.margin-left-right {
    width: 100%;
    /* margin: 0 auto; */
}

/* .conservation, .video-container, .mentors-container {
    width: 85%;
    margin: 0 auto;
} */



.video-container .videos {
    padding: 1.4rem 0 1.2rem 0;
}


.my-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0.5rem 1.4rem;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.my-card:hover {
    box-shadow: 0 42px 60px 0 rgba(0,0,0,.3);
}

.heading {
    font-size: 2rem;
    /* line-height: 8px; */
}

.modal-header {
    background-color: #000;
    color: #fff;
    text-align: center;
    font-weight: 300;
    text-transform: uppercase;
    /* padding: 10px 0px; */
}

.modal-header .btn-close{
    background-color: #fff;
    border: 1px solid #fff;
    opacity: 1;
}
.menter-subtitle{
    color: #000;
    font-size: 20px;
    font-weight: 300;
    text-decoration: underline;
}
.bannerimage{
    width: 100%;
}
.cotation{
    font-style: italic;
    font-size: 20px;
}
.Media .imgSvg {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
}
.Media .ItemBanner {
    /* position: relative; */
    height: 100vh;
    overflow: hidden;
}
.Media .panLeaderText{
    display: flex;
    margin: 30px 0px;
}
.Media .poinLine{
    width: 100%;
    color: #000000;
    height: 2px;
    margin: 31px 0px;
}
.Media .poinText{
    font-size: 2.85rem;
}
.Media .panLeaderCola{
    background-color: #F47D35;
    color: #000000;
    font-size: 2rem;
    margin: auto 0px;
}
.Media .podcastImage{
    padding: 2px;
    background-repeat: no-repeat;
    height: 380px;   
    background-size: contain;
    /* background-position: 0%; */
    /* position: relative; */
}
.Media .image1{
    background-image: url('/public/images/Sting.jpg');
}
.Media .image2{
    background-image: url('/public/images/Sports.jpg');
}
.Media .image3{
    background-image: url('/public/images/SRK.jpg');
}
.Media .image4{
    background-image: url('/public/images/AR-Rehman.jpg');
}
.Media .podcastText{
 /* position: absolute; */
 font-size: 3.5rem;
 color: #F47D35;
 font-weight: 200;
 transform: rotate(270deg) !important;
 margin-top: 224px;
 margin-left: 144px;
}
.Media .contentHeader{
    background-color: orange;
    min-height: 90px;
    font-size: 2rem;
    line-height: 88px;
    text-align: center;
    color: #000000;
    font-weight: 600;
    }
.Media .image{
    width: 100%;
    object-fit: contain;
    padding: 5px;
}
.Media .iconicImage{
    border: 1px solid #ccc;
    width: 98%;
    border-radius: 8px;
    background-color: #fff;
    min-height: 250px;
    text-align: center;
    
}
.Media .knowButton{
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
    color: #000000;
    border: 1px solid #ffc107;
    background-color: transparent;
    margin: 10px 0px;
}
.Media .knowButton:hover{
    background-color:#ffc107;
}
.sMembership{
    border: 1px solid rgba(186, 181, 181, 0.5);
    color: #000;
    font-size: 17px;
    font-weight: 200;
    line-height: 1.38;
    background-color: hsla(0,0%,100%,.97);
    /* background: rgba(186, 181, 181, 0.5); */
}
.sMembership .form{
    padding: 20px;
    border-right: 1px solid rgba(186, 181, 181, 0.5);
    float: left;
    border-width: 0 1px 0 0;
}
.Investorimage{
    height: 50vh;
    object-fit: contain;
}