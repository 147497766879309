@import url('https://fonts.googleapis.com/css2?family=Baskervville&family=PT+Sans:wght@400;700&family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

/* .icon-close2:before {
  content: "\e5cd";
} */

body {
  margin: 0;
  font-family: "Baskerville Old Face";
  /* font-family: 'PT Sans', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: #fff;
}

/* code {
  font-family: "Baskerville Old Face" !important; 
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
.topFix{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
  .nav {
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav1 {
    display: flex;
    justify-content: center;
    font-size: 18px;
    padding-top: 10px;
    
    border: 1px solid red;
  }
  .nav2{
    display: flex;
    justify-content: center;
    font-size: 18px;
    padding: 6px 15px;
    
    border: 1px solid red;
  }
  .nav2 a{
    padding: 6px 15px;
  }
  
.header{
  display: flex;
  justify-content: space-around;
  color: #000;
  align-items: center; 
  background-color: #fff;
}

.my-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.my-container span {
  font-size: 14px;
}

.my-container .logos {
  font-size: 16px;
}

.header .logo {
  font-size: 0.8rem;
  color: #000;
  text-decoration: none;
}

.nav-toggle {
  position: relative;
  font-size: 16px;
}
.NavbarCategoryFixed{
  padding-top: 30px;
}

.no_1_heading{
  background-color: #003da5;
  color: #fff;
  font-size: 69px;
  font-weight: 400;
    text-align: initial;
    text-transform: initial;
}
.heading1:hover{
  text-decoration: underline;
}
.Relentless{
  font-size: 29px;
}
.DonateButton{
  background-color: #0057b8;
  color: #fff;
  border: 1px solid #0057b8;
  border-radius: 25%;
  padding: 4% 8%;
  font-size: 20px;
}

/* .nav-toggle .bar {
  height: 3px;
  width: 100%;
  background-color: black;
  transition: all 100ms ease-in-out;
} */

/* .nav-toggle:hover {
  cursor: pointer;
} */

/* .x:nth-of-type(1) {
  transition: all 100ms ease-in-out;
  transform: rotate(45deg);
  transform-origin: top left;
  width: 28px;
}

.x:nth-of-type(2) {
  transition: all 100ms ease-in-out;
  transform-origin: center;
  width: 0;
}

.x:nth-of-type(3) {
  transition: all 100ms ease-in-out;
  transform: rotate(-45deg);
  transform-origin: bottom left;
  width: 28px;
} */
.logo1 {
  color: #000;
  text-decoration: none;
  font-size: 16px;
 }
 .menuBar{
  height: 85vh;
  position: absolute;
  top: 90px;
  display: flex;
  flex-direction: column;
  background-color: #000;
  width: 100vw;
  overflow-y: auto;
  z-index: 5;
  transition: all 200ms ease-in-out;
 }
 .show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #000;
   border-color: #000;
}
 .headerContainer{
  display: flex;
  flex-direction: column;
  background-color: #fff;
 }
 .rightMenu{
  display: flex;
  align-items: center;
  color: #000;
 }
 .subHeader{
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  overflow-x: scroll;
  background-color: #fff;
  border-bottom: 1px solid #000;
 }

 /* .subHeader a {
  padding: 12px;
 } */

 .sublogo {
  padding: 0px 10px;
  color: #000;
  text-decoration: none;
 }
 .sublogo1{
  text-transform: none;
  margin-inline: 10px;
  font-size: 15px;
  color: #000;
  text-decoration: none;
  line-height: 1;
 }
 .menuNav{
  text-transform: uppercase;
    font-size: 14px;
    line-height: 1;
    text-align: left;
    letter-spacing: 1.3px;
    font-weight: 400;
    border-bottom: 1px solid #fff;
  padding: 13px 25px;
  color: #dcdcdc;
  text-decoration: none;
 }

 .rightMenu .btn-primary{
  background-color: #fff;
  color: #000;
  border: none;
 }

 .rightMenu .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.rightMenu .show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0a58ca;
  border-color: none;
}

 .menuBar .btn-success{
  background-color: #000;
  color: #dcdcdc;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 1.3px;
  text-align: left;
  font-weight: 400;
  padding: 10px 25px;
  border: none !important;
  border-bottom: 1px solid #fff !important;
  width: 100%;
 }
 .menuBar .btn-success:hover{
  color: #0d6efd !important;
 }
 .menuBar .dropdown-item{
  color: #000;
    padding: 10px 19px;
    border-bottom: 1px solid;
 }
 .menuBar .dropdown-item:hover{
  color: #0d6efd;
 }
 .school{
  display: flex;
  justify-content: center;
  font-size: 22px;
  margin-top: 10px;
  font-weight: 600;
  list-style: 1.1;
 }
 .school.subhead{
  font-weight: 500;
  font-size: 18px;
 }
 .school.cotetion{
    font-size: 12px;
    font-style: italic;
 }
 .school.artists{
  font-size: 14px;
 }
 .content{
    font-size: 18px;
    margin-top: 10px;
 }
 .descrption{
  font-size: 20px;
  font-weight: 500;
 }
 /* .footer{
  background-color: #0d2436;
  height: 50vh;
 }
 .footer .contact{
    color: #5a7184;
    font-size: 15px;
 } */
 /*footer*/
.footer{
  background: #000;
}

.ic-one{
  font-size: 24px;
  padding: 10px 10px 10px 10px;
  border-radius: 4px;
  border: 1px solid #2596be;
  color: black;
  margin-left: 10px;
  transition: all 0.5s;
}

.ic-one:hover{
  background: #2596be;
  color: white;
  transition: all 0.5s;
}

.ft-text{
  color: black;
  transition: all 0.5s;
}
.ft-text:hover{
  transform: translateX(8px);
  transition: all 0.5s;
  color: #2596be;
}

.menterimage {
  width: 100%;
  object-fit: contain;
  height: 70vh;
   display: flex;
   justify-content: center;
   padding: 10px;
}

/*footer*/

 @media (min-width: 370px) {

  /* .nav{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .container {
    flex-direction: row;
    justify-content: center;
  } */

  .nav-toggle {
    font-size: 20px;
  }

  .header .logo {
    font-size: 1.2rem;
  }
  .logo1 {
    font-size: 20px;
   }
   .my-container span {
    font-size: 1.1rem;
  }

  .my-container .logos {
    font-size: 0.8rem;
  }
 }
 

 @media (min-width:460px )  {
  .nav{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .my-container {
    flex-direction: row;
    justify-content: center;
  }

  .nav-toggle {
    font-size: 20px;
  }

  .header .logo {
    font-size: 1.4rem;
  }
  .logo1 {
    font-size: 20px;
   }
   .my-container span {
    font-size: 1.1rem;
  }

  .my-container .logos {
    font-size: 1rem;
  }
 
 }

 @media (min-width:768px) {
 
  .nav-toggle {
    font-size: 30px;
  }

  .header .logo {
    font-size: 2.5rem;    
  letter-spacing: 4px;
  }
  .logo1 {
    font-size: 24px;
   }

  .my-container span {
    font-size: 28px;
  }

  .my-container .logos {
    font-size: 1.5rem;
  }

   .subHeader{
    justify-content: center;
    overflow: hidden;
    padding-bottom: 0px;
   }
   .menuBar{
    width: 30vw;
   }
   /* .nav div:nth-child(1){
    width: 16%;
  } */
 }
