.Entrepreneurs .icon-box{
   margin-top: 30px;
}

.Entrepreneurs .centerImage {
    
    width: 311px;
}
.Entrepreneurs .image {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.Entrepreneurs .icon-image{
    border-radius: 100%;
    box-shadow: 0 10px 16px 0 rgba(0,0,0,.1);
    width: 192px;
    height: 192px;
    padding: 5px;
}
.Entrepreneurs .centerHeader{
    text-align: center;
}
.Entrepreneurs .centerText{
    text-align: center;

}