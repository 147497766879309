.home .bannerImage{
    background-image: url('/public/images/stem-cell-jp-bg.jpg');
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.home .image{
    height: 100vh;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.home .bannerImage1{
    background-image: url('/public/images/home-slider18.jpg');
    padding: 10px;
}
.home .bannerImage2{
    background-image: url('/public/images/home-slider22.jpg');
    padding: 10px;
}
.home .bannerImage3{
    background-image: url('/public/images/home-slider17.jpg');
    padding: 10px;
}
.home .bannerImage4{
    background-image: url('/public/images/png_20230516_081746_0000.png');
    /* padding: 10px; */
}
.home .bannerImage5{
    
    
}

.home .bannersmallimg{
    width: 100%;
    object-fit: cover;
}
.home .bannerText{
    font-size: 20px;
    font-family: "Open Sans";
    height: auto;
    width: auto;
    background-color: transparent;
    color: rgb(255, 255, 255);
    text-decoration: none;
    white-space: nowrap;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    text-align: inherit;
    letter-spacing: 0px;
    font-weight: 700;
    transform-origin: 50% 50%;
    opacity: 1;
    transform: translate(0px, 0px);
    visibility: visible;

}
.home .healthCard{
    padding-top: 35px !important;
    padding-right: 20px !important;
    padding-bottom: 35px !important;
    padding-left: 20px !important;
    background-color: #2e3b97;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.home .vc_figure{
    background-color: #ebebeb;
    border-radius: 50%;
    box-shadow: none;
    overflow: hidden;
    padding: 6px;
    vertical-align: top;
    max-width: 100%;
}
.home .vc_figure{
    height: auto;
    max-width: 100%;
    vertical-align: top;
    text-align: center;
}
.home .vc_figure_text{
    text-transform:capitalize;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-top: 20px;
}
.home .vc_figure_button{
    border: 3px solid #fff;
    color: #fff;
    background-color: transparent;
    text-decoration: none;
    padding: 5px;
    border-radius: 5px;
    margin: 20px 0px;
    font-size: 18px;
}
.home .vc_figure_button:hover{
    background-color: #fff;
    color: #2e3b97;
    border: 3px solid #fff;
}
.home .Biological{
    margin-top: 90px;
}
.home .bioHeader{
    color: #2e3b97;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.2;
}
.home .bioText{
    padding: 30px 0px;
    font-size: 14px;
}
.home .bioButton{
    background-color: #f5a81c;
    vertical-align: middle;
    transition-duration: .3s;
    transition-property: box-shadow;
    border: none;
    padding: 5px 10px;
    color: #fff;
    font-size: 16px;
    border-radius: 3px;
    text-decoration: none;
    letter-spacing: 0.01in;
}
.home .bioButton:hover{
    box-shadow: 0 10px 10px -10px rgba(0,0,0,.5);
}
.home .mainCon{
    background-color: #2e3b97;
    opacity: 0.9;
    background-repeat: repeat;
    position: absolute;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.home .Diagnoses{
    background-image: url('/public/images/treatment02.jpg');
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.1);
    background-blend-mode: overlay;
    position: relative;
    width: 100%;
    height: 400px;
    background-size: cover;

}
.home .boderBottom{
    position: absolute;
    overflow: hidden;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
    z-index: 1;
    bottom: -1px;
}
.home .wpex-shape-divider-svg{
    height: 50px;
    display: block;
    width: calc(100% + 1.3px);
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
.home .dioIcon{
    background-color: #ebebeb;
    border-radius: 50%;
    /* position: absolute; */
    height: 100px;
    box-shadow: none;
    overflow: hidden;
    padding: 6px;
}
.home .dioText{
    color: #fff;
    padding-left: 29px
    /* position: absolute; */
}
.home .DiagnosesHeader{
    color: #fff;
    font-size: 32px;
    font-weight: 700;
}
.home .subHeader1{
    color: #fff;
    font-size: 15px;
}
.home .content{
    color: #fff;
    /* position: absolute; */
    width: 83%;
    font-size: 14px;
    padding: 10px 0px;
}
.home .Diabutton{
    /* position: absolute; */
    margin: 150px 0px;
}
.home .centerText{
    color: #2e3b97;
    font-size: 16px;
}
.home .card{
    box-shadow: 0px 4px 10px 0px #dedede;
    border: 1px solid #f8f8f8;
    padding: 10px;
    /* width: 32%;
    margin: 7px */
}
.home .centerImage{
    height: 231px;
    object-fit: contain;
}
.home .centerHeader{
    color: #2e3b97;
    font-size: 18px;
    font-weight: 500;
    margin-top: 10px;
    min-height: 51px;
}
.home .centerButton{
    margin-top: 30px;
    border: 1px solid #dedede;
    background-color: transparent;
    padding: 0.9em 1.2em;
}
.home .centerButton:hover{
    background: #2e3b97!important;
    color: #ffffff!important;
}
.home .Certified{
    background: #2e3b97;
    padding: 15px;
}
.SuccessStories{
    padding-top: 60px;
    background-color: #f8f8f8;
    margin-block: 30px;
}
.SuccessStories .card{
    width: 100%;
    padding-top: 15px;
    background-color: #ffffff;
    box-shadow: 0px 4px 10px 0px #dedede;
    border: 1px solid #f8f8f8;
}
.SuccessStories .storiesImage{
    height: 211px;
    object-fit: cover;
}
@media (min-width: 768px) {
    .home .card{
        width: 32%;
        margin: 7px
    }
    .SuccessStories .card{
        width: 100%;
        margin: 0px;
        /* padding: 5px; */
        /* margin-top: 60px;
        
        background-color: #ffffff;
        box-shadow: 0px 4px 10px 0px #dedede;
        border: 1px solid #f8f8f8; */
    }
    .home .bannerText{
        font-size: 62px;
        line-height: 60px;
    }
    .home .bannerImage{
        flex-direction: row;
    }
    .home .bannerImage1{
        padding: 97px 0px 0px 114px;
    }
    .home .bannerImage2{
        padding: 97px 0px 0px 114px;
    }
    .home .bannerImage3{
        padding: 97px 0px 0px 114px;
    }
    .home .bannerImage5{
        /* padding: 17px 0px 20px 114px; */
    }
    .home .bannersmallimg{
        width: 50%;
    }
}
